import {
    RADIO_UPDATE_BEGIN_TIME_CLIP,
    RADIO_UPDATE_END_TIME_CLIP,
    RADIO_UPDATE_ABS_BEGIN_TIME_CLIP,
    RADIO_UPDATE_ABS_END_TIME_CLIP,
    RADIO_UPDATE_TIMELINE_START,
    RADIO_DISPLAY_CLIP_SLIDER,
    RADIO_SET_TRANSCRIPT,
    RADIO_SET_MODAL_DETAILS,
    RADIO_SET_MODAL_CHANNEL_DATA,
    RADIO_SET_CLIP_TYPE,
    RADIO_SET_CLIP_LENGTH,
    RADIO_SET_PLAYER_TIME,
    RADIO_SET_THE_MANIFEST,
    RADIO_CLEAR_MODAL_DETAILS,
    RADIO_CLEAR_MODAL_CHANNEL_DATA,
    RADIO_SET_SLIDER_RANGE,
    RADIO_SET_SEGMENT_INDEX,
    RADIO_SET_MANIFEST_STRING,
    RADIO_SET_MANIFEST_ROOT,
    RADIO_RESET_RADIO,
    RADIO_SET_TRANSCRIPT_TEXT,
    RADIO_SET_CLIP_TITLE,
    RADIO_SET_CLIP_CHANNEL_ID,
    RADIO_SET_SRT,
    RADIO_SET_CLIP_PREVIEW_IMAGE,
    RADIO_SET_CLIP_BACKGROUND_VIDEO,
    RADIO_SET_LIVE_FRAG_URL,
    RADIO_SET_LIVE_START_TIME
} from '../actionTypes';

const INIT_STATE = {
	RADIO_beginTimeClip: 0,
    RADIO_endTimeClip: 0,
    RADIO_absBeginTimeClip: -1,
    RADIO_absEndTimeClip: 0,
    RADIO_displayClipSlider: false,
    RADIO_timelineStart: 0,
    RADIO_modalDetails: null,
    RADIO_modalChannelData: null,
    RADIO_transcript: [],
    RADIO_clipType: 'start',
    RADIO_clipLength: 5,
    RADIO_playerTime: 0,
    RADIO_segmentIndex: 0,
    RADIO_sliderRange: {min: 0, max: 100},
    RADIO_theManifest: null,
    RADIO_manifestString: null,
    RADIO_manifestRoot: null,
    RADIO_transcriptText: null,
    RADIO_clipTitle: "",
    RADIO_clipChannelId: null,
    RADIO_clipPreviewImage: null,
    RADIO_clipBackgroundVideo: null,
    RADIO_liveFragUrl: null,
    RADIO_srt: null,
    RADIO_liveStartTime: null,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case RADIO_UPDATE_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                RADIO_beginTimeClip:action.payload
            };
        case RADIO_UPDATE_END_TIME_CLIP:
            return { 
            ...state, 
                RADIO_endTimeClip:action.payload
            };
        case RADIO_UPDATE_ABS_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                RADIO_absBeginTimeClip:action.payload
            };
        case RADIO_UPDATE_ABS_END_TIME_CLIP:
            return { 
            ...state, 
                RADIO_absEndTimeClip:action.payload
            };
        case RADIO_DISPLAY_CLIP_SLIDER:
            return { 
            ...state, 
                RADIO_displayClipSlider:action.payload
            };
        case RADIO_UPDATE_TIMELINE_START:
            return { 
            ...state, 
                RADIO_timelineStart:action.payload
            };
        case RADIO_SET_TRANSCRIPT:
            return { 
            ...state, 
                RADIO_transcript:action.payload
            };
        case RADIO_SET_MODAL_DETAILS:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.RADIO_modalDetails);
                return {
                    ...state,
                    RADIO_modalDetails: newState,
                };
            } else {
                return {
                    ...state,
                    RADIO_modalDetails: {
                        ...state.RADIO_modalDetails,
                        ...action.payload,
                    },
                };
            }
        case RADIO_CLEAR_MODAL_DETAILS:
            return { 
                ...state, 
                    RADIO_modalDetails:null
                };
        case RADIO_SET_MODAL_CHANNEL_DATA:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.RADIO_modalChannelData);
                return {
                    ...state,
                    RADIO_modalChannelData: newState,
                };
            } else {
                return {
                    ...state,
                    RADIO_modalChannelData: {
                        ...state.RADIO_modalChannelData,
                        ...action.payload,
                    },
                };
            }
            case RADIO_SET_SLIDER_RANGE:
                if (typeof action.payload === 'function') {
                    const newState = action.payload(state.RADIO_sliderRange);
                    return {
                        ...state,
                        RADIO_sliderRange: newState,
                    };
                } else {
                    return {
                        ...state,
                        RADIO_sliderRange: {
                            ...state.RADIO_sliderRange,
                            ...action.payload,
                        },
                    };
                }
        case RADIO_CLEAR_MODAL_CHANNEL_DATA:
            return { 
                ...state, 
                    RADIO_modalChannelData:null
                    };
        case RADIO_SET_CLIP_TYPE:
            return { 
            ...state, 
                RADIO_clipType:action.payload
            };
        case RADIO_SET_CLIP_LENGTH:
            return { 
            ...state, 
                RADIO_clipLength:action.payload
            };
        case RADIO_SET_PLAYER_TIME:
            return { 
            ...state, 
                RADIO_playerTime:action.payload
            };
        case RADIO_SET_THE_MANIFEST:
            return { 
            ...state, 
                RADIO_theManifest:action.payload
            };
        case RADIO_SET_MANIFEST_STRING:
            return { 
            ...state, 
                RADIO_manifestString:action.payload
            };
        case RADIO_SET_MANIFEST_ROOT:
            return { 
            ...state, 
                RADIO_manifestRoot:action.payload
            };    
        case RADIO_SET_SEGMENT_INDEX:
            return { 
            ...state, 
                RADIO_segmentIndex:action.payload
            };
        case RADIO_SET_TRANSCRIPT_TEXT:
            return { 
            ...state, 
                RADIO_transcriptText:action.payload
            };
        case RADIO_SET_CLIP_TITLE:
            return { 
            ...state, 
                RADIO_clipTitle:action.payload
            };
        case RADIO_SET_CLIP_CHANNEL_ID:
            return { 
            ...state, 
                RADIO_clipChannelId:action.payload
            };
        case RADIO_SET_CLIP_PREVIEW_IMAGE:
            return { 
            ...state, 
                RADIO_clipPreviewImage:action.payload
            };
        case RADIO_SET_CLIP_BACKGROUND_VIDEO:
            return { 
            ...state, 
                RADIO_clipBackgroundVideo:action.payload
            };
        case RADIO_SET_LIVE_FRAG_URL:
            return { 
            ...state, 
                RADIO_liveFragUrl:action.payload
            };
            case RADIO_SET_LIVE_START_TIME:
                return { 
                ...state, 
                    RADIO_liveStartTime:action.payload
                };
        case RADIO_SET_SRT:
            return { 
            ...state, 
                RADIO_srt:action.payload
            };
        case RADIO_RESET_RADIO:
            return INIT_STATE;
		default: 
    		return { 
                ...state 
            };
	}
}