export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const LOGIN_USER_FACEBOOK = 'LOGIN_USER_FACEBOOK';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_EMAIL_PASSWORD_FIREBASE = 'LOGIN_USER_EMAIL_PASSWORD_FIREBASE';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FACEBOOK_SUCCESS = 'LOGIN_USER_FACEBOOK_SUCCESS';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER_BOT_TOKEN = 'UPDATE_USER_BOT_TOKEN';
export const UPDATE_USER_FB_TOKEN = 'UPDATE_USER_FB_TOKEN';
export const SET_ACCESS_LEVEL = 'SET_ACCESS_LEVEL';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

// tv player
export const TV_UPDATE_BEGIN_TIME_CLIP = "TV_UPDATE_BEGIN_TIME_CLIP";
export const TV_UPDATE_END_TIME_CLIP = "TV_UPDATE_END_TIME_CLIP";
export const TV_UPDATE_ABS_BEGIN_TIME_CLIP = "TV_UPDATE_ABS_BEGIN_TIME_CLIP";
export const TV_UPDATE_ABS_END_TIME_CLIP = "TV_UPDATE_ABS_END_TIME_CLIP";
export const TV_DISPLAY_CLIP_SLIDER = "TV_DISPLAY_CLIP_SLIDER";
export const TV_UPDATE_TIMELINE_START = "TV_UPDATE_TIMELINE_START";
export const TV_SET_TRANSCRIPT = "TV_SET_TRANSCRIPT";
export const TV_SET_MODAL_DETAILS = "TV_SET_MODAL_DETAILS";
export const TV_SET_MODAL_CHANNEL_DATA = "TV_SET_MODAL_CHANNEL_DATA";
export const TV_SET_CLIP_TYPE = "TV_SET_CLIP_TYPE";
export const TV_SET_CLIP_LENGTH = "TV_SET_CLIP_LENGTH";
export const TV_SET_PLAYER_TIME = "TV_SET_PLAYER_TIME";
export const TV_SET_THE_MANIFEST = "TV_SET_THE_MANIFEST";
export const TV_CLEAR_MODAL_DETAILS = "TV_CLEAR_MODAL_DETAILS";
export const TV_CLEAR_MODAL_CHANNEL_DATA = "TV_CLEAR_MODAL_CHANNEL_DATA";
export const TV_SET_SLIDER_RANGE = "TV_SET_SLIDER_RANGE";
export const TV_SET_SEGMENT_INDEX = "TV_SET_SEGMENT_INDEX";
export const TV_SET_MANIFEST_STRING = "TV_SET_MANIFEST_STRING";
export const TV_SET_MANIFEST_ROOT = "TV_SET_MANIFEST_ROOT";
export const TV_RESET_TV = "TV_RESET_TV";
export const TV_SET_TRANSCRIPT_MODAL_DIRECTION = "TV_SET_TRANSCRIPT_MODAL_DIRECTION";
export const TV_SET_TRANSCRIPT_TEXT = "TV_SET_TRANSCRIPT_TEXT";
export const TV_SET_SRT = "TV_SET_SRT";
export const TV_SET_CLIP_TITLE = "TV_SET_CLIP_TITLE";
export const TV_SET_CLIP_CHANNEL_ID = "TV_SET_CLIP_CHANNEL_ID";
export const TV_SET_CLIP_PREVIEW_IMAGE = "TV_SET_CLIP_PREVIEW_IMAGE";
export const TV_SET_CLIP_BACKGROUND_VIDEO = "TV_SET_CLIP_BACKGROUND_VIDEO";
export const TV_SET_BACK_AGGREGATE = "TV_SET_BACK_AGGREGATE"
export const TV_SET_FORWARD_AGGREGATE = "TV_SET_FORWARD_AGGREGATE"
export const TV_SET_LAST_TRANSCRIPT_DIRECTION = "TV_SET_LAST_TRANSCRIPT_DIRECTION"
export const TV_SET_MIN_START_TRANSCRIPT_DATE = "TV_SET_MIN_START_TRANSCRIPT_DATE"
export const TV_SET_MAX_START_TRANSCRIPT_DATE = "TV_SET_MAX_START_TRANSCRIPT_DATE"
export const TV_SET_MIN_END_TRANSCRIPT_DATE = "TV_SET_MIN_END_TRANSCRIPT_DATE"
export const TV_SET_MAX_END_TRANSCRIPT_DATE = "TV_SET_MAX_END_TRANSCRIPT_DATE"
export const TV_SET_IS_169_CROP_ON = "TV_SET_IS_169_CROP_ON"
export const TV_SET_IS_916_CROP_ON = "TV_SET_IS_916_CROP_ON"
export const TV_SET_IS_11_CROP_ON = "TV_SET_IS_11_CROP_ON"
export const TV_SET_CROP_ASPECT = "TV_SET_CROP_ASPECT"
export const TV_SET_CROP_OBJ = "TV_SET_CROP_OBJ"

// radio player
export const RADIO_UPDATE_BEGIN_TIME_CLIP = "RADIO_UPDATE_BEGIN_TIME_CLIP";
export const RADIO_UPDATE_END_TIME_CLIP = "RADIO_UPDATE_END_TIME_CLIP";
export const RADIO_UPDATE_ABS_BEGIN_TIME_CLIP = "RADIO_UPDATE_ABS_BEGIN_TIME_CLIP";
export const RADIO_UPDATE_ABS_END_TIME_CLIP = "RADIO_UPDATE_ABS_END_TIME_CLIP";
export const RADIO_DISPLAY_CLIP_SLIDER = "RADIO_DISPLAY_CLIP_SLIDER";
export const RADIO_UPDATE_TIMELINE_START = "RADIO_UPDATE_TIMELINE_START";
export const RADIO_SET_TRANSCRIPT = "RADIO_SET_TRANSCRIPT";
export const RADIO_SET_MODAL_DETAILS = "RADIO_SET_MODAL_DETAILS";
export const RADIO_SET_MODAL_CHANNEL_DATA = "RADIO_SET_MODAL_CHANNEL_DATA";
export const RADIO_SET_CLIP_TYPE = "RADIO_SET_CLIP_TYPE";
export const RADIO_SET_CLIP_LENGTH = "RADIO_SET_CLIP_LENGTH";
export const RADIO_SET_PLAYER_TIME = "RADIO_SET_PLAYER_TIME";
export const RADIO_SET_THE_MANIFEST = "RADIO_SET_THE_MANIFEST";
export const RADIO_CLEAR_MODAL_DETAILS = "RADIO_CLEAR_MODAL_DETAILS";
export const RADIO_CLEAR_MODAL_CHANNEL_DATA = "RADIO_CLEAR_MODAL_CHANNEL_DATA";
export const RADIO_SET_SLIDER_RANGE = "RADIO_SET_SLIDER_RANGE";
export const RADIO_SET_SEGMENT_INDEX = "RADIO_SET_SEGMENT_INDEX";
export const RADIO_SET_MANIFEST_STRING = "RADIO_SET_MANIFEST_STRING";
export const RADIO_SET_MANIFEST_ROOT = "RADIO_SET_MANIFEST_ROOT";
export const RADIO_RESET_RADIO = "RADIO_RESET_RADIO";
export const RADIO_SET_TRANSCRIPT_MODAL_DIRECTION = "RADIO_SET_TRANSCRIPT_MODAL_DIRECTION";
export const RADIO_SET_TRANSCRIPT_TEXT = "RADIO_SET_TRANSCRIPT_TEXT";
export const RADIO_SET_SRT = "RADIO_SET_SRT";
export const RADIO_SET_CLIP_TITLE = "RADIO_SET_CLIP_TITLE";
export const RADIO_SET_CLIP_CHANNEL_ID = "RADIO_SET_CLIP_CHANNEL_ID";
export const RADIO_SET_CLIP_PREVIEW_IMAGE = "RADIO_SET_CLIP_PREVIEW_IMAGE";
export const RADIO_SET_CLIP_BACKGROUND_VIDEO = "RADIO_SET_CLIP_BACKGROUND_VIDEO";
export const RADIO_SET_BACK_AGGREGATE = "RADIO_SET_BACK_AGGREGATE"
export const RADIO_SET_FORWARD_AGGREGATE = "RADIO_SET_FORWARD_AGGREGATE"
export const RADIO_SET_LAST_TRANSCRIPT_DIRECTION = "RADIO_SET_LAST_TRANSCRIPT_DIRECTION"
export const RADIO_SET_MIN_START_TRANSCRIPT_DATE = "RADIO_SET_MIN_START_TRANSCRIPT_DATE"
export const RADIO_SET_MAX_START_TRANSCRIPT_DATE = "RADIO_SET_MAX_START_TRANSCRIPT_DATE"
export const RADIO_SET_MIN_END_TRANSCRIPT_DATE = "RADIO_SET_MIN_END_TRANSCRIPT_DATE"
export const RADIO_SET_MAX_END_TRANSCRIPT_DATE = "RADIO_SET_MAX_END_TRANSCRIPT_DATE"
export const RADIO_SET_LIVE_FRAG_URL = "RADIO_SET_LIVE_FRAG_URL"
export const RADIO_SET_LIVE_START_TIME = "RADIO_SET_LIVE_START_TIME"

// podcast
export const PODCAST_UPDATE_BEGIN_TIME_CLIP = "PODCAST_UPDATE_BEGIN_TIME_CLIP"
export const PODCAST_UPDATE_END_TIME_CLIP = "PODCAST_UPDATE_END_TIME_CLIP"
export const PODCAST_UPDATE_ABS_BEGIN_TIME_CLIP = "PODCAST_UPDATE_ABS_BEGIN_TIME_CLIP"
export const PODCAST_UPDATE_ABS_END_TIME_CLIP = "PODCAST_UPDATE_ABS_END_TIME_CLIP"
export const PODCAST_UPDATE_TIMELINE_START = "PODCAST_UPDATE_TIMELINE_START"
export const PODCAST_DISPLAY_CLIP_SLIDER = "PODCAST_DISPLAY_CLIP_SLIDER"
export const PODCAST_SET_TRANSCRIPT = "PODCAST_SET_TRANSCRIPT"
export const PODCAST_SET_MODAL_DETAILS = "PODCAST_SET_MODAL_DETAILS"
export const PODCAST_SET_MODAL_CHANNEL_DATA = "PODCAST_SET_MODAL_CHANNEL_DATA"
export const PODCAST_SET_CLIP_TYPE = "PODCAST_SET_CLIP_TYPE"
export const PODCAST_SET_CLIP_LENGTH = "PODCAST_SET_CLIP_LENGTH"
export const PODCAST_SET_PLAYER_TIME = "PODCAST_SET_PLAYER_TIME"
export const PODCAST_SET_THE_MANIFEST = "PODCAST_SET_THE_MANIFEST"
export const PODCAST_CLEAR_MODAL_DETAILS = "PODCAST_CLEAR_MODAL_DETAILS"
export const PODCAST_CLEAR_MODAL_CHANNEL_DATA = "PODCAST_CLEAR_MODAL_CHANNEL_DATA"
export const PODCAST_SET_SLIDER_RANGE = "PODCAST_SET_SLIDER_RANGE"
export const PODCAST_SET_SEGMENT_INDEX = "PODCAST_SET_SEGMENT_INDEX"
export const PODCAST_SET_MANIFEST_STRING = "PODCAST_SET_MANIFEST_STRING"
export const PODCAST_SET_MANIFEST_ROOT = "PODCAST_SET_MANIFEST_ROOT"
export const PODCAST_RESET_PODCAST = "PODCAST_RESET_PODCAST"
export const PODCAST_SET_TRANSCRIPT_MODAL_DIRECTION = "PODCAST_SET_TRANSCRIPT_MODAL_DIRECTION";
export const PODCAST_SET_TRANSCRIPT_TEXT = "PODCAST_SET_TRANSCRIPT_TEXT"
export const PODCAST_SET_CLIP_TITLE = "PODCAST_SET_CLIP_TITLE"
export const PODCAST_SET_CLIP_CHANNEL_ID = "PODCAST_SET_CLIP_CHANNEL_ID"
export const PODCAST_SET_SRT = "PODCAST_SET_SRT"
export const PODCAST_SET_CLIP_PREVIEW_IMAGE = "PODCAST_SET_CLIP_PREVIEW_IMAGE"
export const PODCAST_SET_CLIP_BACKGROUND_VIDEO = "PODCAST_SET_CLIP_BACKGROUND_VIDEO"
export const PODCAST_SET_ITEM_AUDIO_URL = "PODCAST_SET_ITEM_AUDIO_URL"
export const PODCAST_SET_BACK_AGGREGATE = "PODCAST_SET_BACK_AGGREGATE"
export const PODCAST_SET_FORWARD_AGGREGATE = "PODCAST_SET_FORWARD_AGGREGATE"
export const PODCAST_SET_CHANNEL_ID = "PODCAST_SET_CHANNEL_ID"
export const PODCAST_SET_CHANNEL_NAME = "PODCAST_SET_CHANNEL_NAME"
export const PODCAST_SET_CHANNEL_IMAGE = "PODCAST_SET_CHANNEL_IMAGE"
export const PODCAST_SET_PUBLISHED_DATE_TIME = "PODCAST_SET_PUBLISHED_DATE_TIME"
export const PODCAST_SET_ITEM_TITLE = "PODCAST_SET_ITEM_TITLE"

// committee player
export const COMMITTEE_UPDATE_BEGIN_TIME_CLIP = "COMMITTEE_UPDATE_BEGIN_TIME_CLIP";
export const COMMITTEE_UPDATE_END_TIME_CLIP = "COMMITTEE_UPDATE_END_TIME_CLIP";
export const COMMITTEE_UPDATE_ABS_BEGIN_TIME_CLIP = "COMMITTEE_UPDATE_ABS_BEGIN_TIME_CLIP";
export const COMMITTEE_UPDATE_ABS_END_TIME_CLIP = "COMMITTEE_UPDATE_ABS_END_TIME_CLIP";
export const COMMITTEE_DISPLAY_CLIP_SLIDER = "COMMITTEE_DISPLAY_CLIP_SLIDER";
export const COMMITTEE_UPDATE_TIMELINE_START = "COMMITTEE_UPDATE_TIMELINE_START";
export const COMMITTEE_SET_TRANSCRIPT = "COMMITTEE_SET_TRANSCRIPT";
export const COMMITTEE_SET_MODAL_DETAILS = "COMMITTEE_SET_MODAL_DETAILS";
export const COMMITTEE_SET_MODAL_CHANNEL_DATA = "COMMITTEE_SET_MODAL_CHANNEL_DATA";
export const COMMITTEE_SET_CLIP_TYPE = "COMMITTEE_SET_CLIP_TYPE";
export const COMMITTEE_SET_CLIP_LENGTH = "COMMITTEE_SET_CLIP_LENGTH";
export const COMMITTEE_SET_PLAYER_TIME = "COMMITTEE_SET_PLAYER_TIME";
export const COMMITTEE_SET_THE_MANIFEST = "COMMITTEE_SET_THE_MANIFEST";
export const COMMITTEE_CLEAR_MODAL_DETAILS = "COMMITTEE_CLEAR_MODAL_DETAILS";
export const COMMITTEE_CLEAR_MODAL_CHANNEL_DATA = "COMMITTEE_CLEAR_MODAL_CHANNEL_DATA";
export const COMMITTEE_SET_SLIDER_RANGE = "COMMITTEE_SET_SLIDER_RANGE";
export const COMMITTEE_SET_SEGMENT_INDEX = "COMMITTEE_SET_SEGMENT_INDEX";
export const COMMITTEE_SET_MANIFEST_STRING = "COMMITTEE_SET_MANIFEST_STRING";
export const COMMITTEE_SET_MANIFEST_ROOT = "COMMITTEE_SET_MANIFEST_ROOT";
export const COMMITTEE_RESET_COMMITTEE = "COMMITTEE_RESET_COMMITTEE";
export const COMMITTEE_SET_TRANSCRIPT_MODAL_DIRECTION = "COMMITTEE_SET_TRANSCRIPT_MODAL_DIRECTION";
export const COMMITTEE_SET_TRANSCRIPT_TEXT = "COMMITTEE_SET_TRANSCRIPT_TEXT";
export const COMMITTEE_SET_SRT = "COMMITTEE_SET_SRT";
export const COMMITTEE_SET_CLIP_TITLE = "COMMITTEE_SET_CLIP_TITLE";
export const COMMITTEE_SET_CLIP_CHANNEL_ID = "COMMITTEE_SET_CLIP_CHANNEL_ID";
export const COMMITTEE_SET_CLIP_PREVIEW_IMAGE = "COMMITTEE_SET_CLIP_PREVIEW_IMAGE";
export const COMMITTEE_SET_CLIP_BACKGROUND_VIDEO = "COMMITTEE_SET_CLIP_BACKGROUND_VIDEO";
export const COMMITTEE_SET_BACK_AGGREGATE = "COMMITTEE_SET_BACK_AGGREGATE"
export const COMMITTEE_SET_FORWARD_AGGREGATE = "COMMITTEE_SET_FORWARD_AGGREGATE"
export const COMMITTEE_SET_LAST_TRANSCRIPT_DIRECTION = "COMMITTEE_SET_LAST_TRANSCRIPT_DIRECTION"
export const COMMITTEE_SET_MIN_START_TRANSCRIPT_DATE = "COMMITTEE_SET_MIN_START_TRANSCRIPT_DATE"
export const COMMITTEE_SET_MAX_START_TRANSCRIPT_DATE = "COMMITTEE_SET_MAX_START_TRANSCRIPT_DATE"
export const COMMITTEE_SET_MIN_END_TRANSCRIPT_DATE = "COMMITTEE_SET_MIN_END_TRANSCRIPT_DATE"
export const COMMITTEE_SET_MAX_END_TRANSCRIPT_DATE = "COMMITTEE_SET_MAX_END_TRANSCRIPT_DATE"
export const COMMITTEE_SET_IS_169_CROP_ON = "COMMITTEE_SET_IS_169_CROP_ON"
export const COMMITTEE_SET_IS_916_CROP_ON = "COMMITTEE_SET_IS_916_CROP_ON"
export const COMMITTEE_SET_IS_11_CROP_ON = "COMMITTEE_SET_IS_11_CROP_ON"
export const COMMITTEE_SET_CROP_ASPECT = "COMMITTEE_SET_CROP_ASPECT"
export const COMMITTEE_SET_CROP_OBJ = "COMMITTEE_SET_CROP_OBJ"
export const COMMITTEE_SET_START_CLIP_ID = "COMMITTEE_SET_START_CLIP_ID"
export const COMMITTEE_SET_END_CLIP_ID = "COMMITTEE_SET_END_CLIP_ID"
export const COMMITTEE_SET_PREVIOUS_CLIP_ID = "COMMITTEE_SET_PREVIOUS_CLIP_ID"
export const COMMITTEE_SET_NEXT_CLIP_ID = "COMMITTEE_SET_NEXT_CLIP_ID"
export const COMMITTEE_SET_PLAYER_RANDOM_STRING = "COMMITTEE_SET_PLAYER_RANDOM_STRING"
export const COMMITTEE_SET_CLIP_EARLIEST_DATE = "COMMITTEE_SET_CLIP_EARLIEST_DATE"
export const COMMITTEE_SET_CLIP_LATEST_DATE = "COMMITTEE_SET_CLIP_LATEST_DATE"
export const COMMITTEE_SET_CLIP_CAPTIONS_EARLIEST_DATE = "COMMITTEE_SET_CLIP_CAPTIONS_EARLIEST_DATE"
export const COMMITTEE_SET_CLIP_CAPTIONS_LATEST_DATE = "COMMITTEE_SET_CLIP_CAPTIONS_LATEST_DATE"
export const COMMITTEE_SET_CAPTIONS = "COMMITTEE_SET_CAPTIONS"
export const COMMITTEE_SET_PLAYER_CURRENT_TIME = "COMMITTEE_SET_PLAYER_CURRENT_TIME"
export const COMMITTEE_SET_SEGMENTS = "COMMITTEE_SET_SEGMENTS"
export const COMMITTEE_SET_ADDED_TIME = "COMMITTEE_SET_ADDED_TIME"
export const COMMITTEE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_START = "COMMITTEE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_START"
export const COMMITTEE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_END = "COMMITTEE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_END"
export const COMMITTEE_SET_ALIGNED_GLOBALLY = "COMMITTEE_SET_ALIGNED_GLOBALLY"
export const COMMITTEE_SET_ALIGNED_LOCALLY = "COMMITTEE_SET_ALIGNED_LOCALLY"
export const COMMITTEE_SET_ADDED_PLAYER_TIME = "COMMITTEE_SET_ADDED_PLAYER_TIME"
export const COMMITTEE_SET_CLIP_LOAD_BUTTON = "COMMITTEE_SET_CLIP_LOAD_BUTTON"
export const COMMITTEE_SET_TOTAL_CAPTION_ADJUSTMENT = "COMMITTEE_SET_TOTAL_CAPTION_ADJUSTMENT"