import { makeVar } from '@apollo/client'
import { InMemoryCache } from 'apollo-cache-inmemory'

export const userLoggedIn = makeVar(!!localStorage.getItem('username'));
export const projectSelected = makeVar(!!localStorage.getItem('project_id'));
export const superAdminLoggedIn = makeVar(!!localStorage.getItem('superadmin'));
export const adminLoggedIn = makeVar(!!localStorage.getItem('admin'));
export const isVirgin = makeVar(!!localStorage.getItem('virgin'));
export const activeMap = makeVar(null);

export const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          activeMap: { read() { return activeMap(); } },
          userLoggedIn: { read() { return userLoggedIn(); } },
          projectSelected: { read() { return projectSelected(); } },
          superAdminLoggedIn: { read() { return superAdminLoggedIn(); } },
          adminLoggedIn: { read() { return adminLoggedIn(); } },
        }
      }
    }
  });