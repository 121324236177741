import {
    TV_UPDATE_BEGIN_TIME_CLIP,
    TV_UPDATE_END_TIME_CLIP,
    TV_UPDATE_ABS_BEGIN_TIME_CLIP,
    TV_UPDATE_ABS_END_TIME_CLIP,
    TV_UPDATE_TIMELINE_START,
    TV_DISPLAY_CLIP_SLIDER,
    TV_SET_TRANSCRIPT,
    TV_SET_MODAL_DETAILS,
    TV_SET_MODAL_CHANNEL_DATA,
    TV_SET_CLIP_TYPE,
    TV_SET_CLIP_LENGTH,
    TV_SET_PLAYER_TIME,
    TV_SET_THE_MANIFEST,
    TV_CLEAR_MODAL_DETAILS,
    TV_CLEAR_MODAL_CHANNEL_DATA,
    TV_SET_SLIDER_RANGE,
    TV_SET_SEGMENT_INDEX,
    TV_SET_MANIFEST_STRING,
    TV_SET_MANIFEST_ROOT,
    TV_RESET_TV,
    TV_SET_TRANSCRIPT_TEXT,
    TV_SET_CLIP_TITLE,
    TV_SET_CLIP_CHANNEL_ID,
    TV_SET_SRT,
    TV_SET_CLIP_PREVIEW_IMAGE,
    TV_SET_CLIP_BACKGROUND_VIDEO,
    TV_SET_IS_169_CROP_ON,
    TV_SET_IS_916_CROP_ON,
    TV_SET_IS_11_CROP_ON,
    TV_SET_CROP_ASPECT,
    TV_SET_CROP_OBJ
} from '../actionTypes';

const INIT_STATE = {
	TV_beginTimeClip: 0,
    TV_endTimeClip: 0,
    TV_absBeginTimeClip: -1,
    TV_absEndTimeClip: 0,
    TV_displayClipSlider: false,
    TV_timelineStart: 0,
    TV_modalDetails: null,
    TV_modalChannelData: null,
    TV_transcript: [],
    TV_clipType: 'start',
    TV_clipLength: 5,
    TV_playerTime: 0,
    TV_segmentIndex: 0,
    TV_sliderRange: {min: 0, max: 100},
    TV_theManifest: null,
    TV_manifestString: null,
    TV_manifestRoot: null,
    TV_transcriptText: null,
    TV_clipTitle: "",
    TV_clipChannelId: null,
    TV_clipPreviewImage: null,
    TV_clipBackgroundVideo: null,
    TV_srt: null,
    TV_is169CropOn: false,
    TV_is916CropOn: false,
    TV_is11CropOn: false,
    TV_cropAspect: 16 / 9,
    TV_cropObj: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case TV_UPDATE_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                TV_beginTimeClip:action.payload
            };
        case TV_UPDATE_END_TIME_CLIP:
            return { 
            ...state, 
                TV_endTimeClip:action.payload
            };
        case TV_UPDATE_ABS_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                TV_absBeginTimeClip:action.payload
            };
        case TV_UPDATE_ABS_END_TIME_CLIP:
            return { 
            ...state, 
                TV_absEndTimeClip:action.payload
            };
        case TV_DISPLAY_CLIP_SLIDER:
            return { 
            ...state, 
                TV_displayClipSlider:action.payload
            };
        case TV_UPDATE_TIMELINE_START:
            return { 
            ...state, 
                TV_timelineStart:action.payload
            };
        case TV_SET_TRANSCRIPT:
            return { 
            ...state, 
                TV_transcript:action.payload
            };
        case TV_SET_MODAL_DETAILS:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.TV_modalDetails);
                return {
                    ...state,
                    TV_modalDetails: newState,
                };
            } else {
                return {
                    ...state,
                    TV_modalDetails: {
                        ...state.TV_modalDetails,
                        ...action.payload,
                    },
                };
            }
        case TV_CLEAR_MODAL_DETAILS:
            return { 
                ...state, 
                    TV_modalDetails:null
                };
        case TV_SET_MODAL_CHANNEL_DATA:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.TV_modalChannelData);
                return {
                    ...state,
                    TV_modalChannelData: newState,
                };
            } else {
                return {
                    ...state,
                    TV_modalChannelData: {
                        ...state.TV_modalChannelData,
                        ...action.payload,
                    },
                };
            }
        case TV_SET_SLIDER_RANGE:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.TV_sliderRange);
                return {
                    ...state,
                    TV_sliderRange: newState,
                };
            } else {
                return {
                    ...state,
                    TV_sliderRange: {
                        ...state.TV_sliderRange,
                        ...action.payload,
                    },
                };
            }
        case TV_CLEAR_MODAL_CHANNEL_DATA:
            return { 
                ...state, 
                    TV_modalChannelData:null
                    };
        case TV_SET_CLIP_TYPE:
            return { 
            ...state, 
                TV_clipType:action.payload
            };
        case TV_SET_CLIP_LENGTH:
            return { 
            ...state, 
                TV_clipLength:action.payload
            };
        case TV_SET_PLAYER_TIME:
            return { 
            ...state, 
                TV_playerTime:action.payload
            };
        case TV_SET_THE_MANIFEST:
            return { 
            ...state, 
                TV_theManifest:action.payload
            };
        case TV_SET_MANIFEST_STRING:
            return { 
            ...state, 
                TV_manifestString:action.payload
            };
        case TV_SET_MANIFEST_ROOT:
            return { 
            ...state, 
                TV_manifestRoot:action.payload
            };    
        case TV_SET_SEGMENT_INDEX:
            return { 
            ...state, 
                TV_segmentIndex:action.payload
            };
        case TV_SET_TRANSCRIPT_TEXT:
            return { 
            ...state, 
                TV_transcriptText:action.payload
            };
        case TV_SET_CLIP_TITLE:
            return { 
            ...state, 
                TV_clipTitle:action.payload
            };
        case TV_SET_CLIP_CHANNEL_ID:
            return { 
            ...state, 
                TV_clipChannelId:action.payload
            };
        case TV_SET_CLIP_PREVIEW_IMAGE:
            return { 
            ...state, 
                TV_clipPreviewImage:action.payload
            };
        case TV_SET_CLIP_BACKGROUND_VIDEO:
            return { 
            ...state, 
                TV_clipBackgroundVideo:action.payload
            };
        case TV_SET_SRT:
            return { 
            ...state, 
                TV_srt:action.payload
            };
        case TV_SET_IS_169_CROP_ON:
            return { 
            ...state, 
                TV_is169CropOn:action.payload
            };    
        case TV_SET_IS_916_CROP_ON:
            return { 
            ...state, 
                TV_is916CropOn:action.payload
            };    
        case TV_SET_IS_11_CROP_ON:
            return { 
            ...state, 
                TV_is11CropOn:action.payload
            };    
        case TV_SET_CROP_ASPECT:
            return { 
            ...state, 
                TV_cropAspect:action.payload
            };        
        case TV_SET_CROP_OBJ:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.TV_cropObj);
                return {
                    ...state,
                    TV_cropObj: newState,
                };
            } else {
                return {
                    ...state,
                    TV_cropObj: {
                        ...state.TV_cropObj,
                        ...action.payload,
                    },
                };
            };      
        case TV_RESET_TV:
            return INIT_STATE;
		default: 
    		return { 
                ...state 
            };
	}
}