import {
    LOGIN_USER_FACEBOOK,
    LOGIN_USER,
    LOGIN_USER_EMAIL_PASSWORD_FIREBASE,
    LOGIN_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGOUT_USER,
    UPDATE_USER_BOT_TOKEN,
    UPDATE_USER_FB_TOKEN
} from '../actionTypes';
import { auth } from '../../firebase';

import jwtdecode from 'jwt-decode';

const reducer = (state = [], action) => {
    switch (action.type) {
        case LOGIN_USER_FACEBOOK:
            console.log(action.payload);
            var decodedtoken = jwtdecode(action.payload.token.token);
            console.log(decodedtoken);
            console.log(action.payload.token);
            var userObj = {};
            userObj.access_level = decodedtoken.access_level;
            userObj.name = action.payload.token.d;
            userObj.profile_image = action.payload.token.profile_image;
            userObj.user_id = action.payload.token.a;
            userObj.token = action.payload.token.token;
            return Object.assign({}, state, userObj);
        case LOGIN_USER_EMAIL_PASSWORD_FIREBASE:
            console.log(action.dataObj);    
            return Object.assign({}, state, action.dataObj);
        case UPDATE_USER_BOT_TOKEN:
            console.log(action.payload)
            localStorage.setItem('token', action.payload.token);
            return { ...state, 
                        token: action.payload.token
                    };
        case UPDATE_USER_FB_TOKEN:
            console.log(action.payload)
            localStorage.setItem('fb_token', action.payload.fb_token);
            return { ...state, 
                        fb_token: action.payload.fb_token
                    };                                        
        case LOGIN_USER:
            console.log(action.dataObj);    
            return Object.assign({}, state, action.dataObj);
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload.uid };
        case LOGOUT_USER:
            return state = {};
        default: return state;
    }
}

export default reducer;