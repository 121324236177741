export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_ASIDE_SHOW = "SET_ASIDE_SHOW";
export const SET_FEDERAL_POLL_MAP = "SET_FEDERAL_POLL_MAP";
export const SET_SIDEBAR_SHOW = "SET_SIDEBAR_SHOW";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_SHOW_INFOBOX = "SET_SHOW_INFOBOX";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";