import {
    PODCAST_UPDATE_BEGIN_TIME_CLIP,
    PODCAST_UPDATE_END_TIME_CLIP,
    PODCAST_UPDATE_ABS_BEGIN_TIME_CLIP,
    PODCAST_UPDATE_ABS_END_TIME_CLIP,
    PODCAST_UPDATE_TIMELINE_START,
    PODCAST_DISPLAY_CLIP_SLIDER,
    PODCAST_SET_TRANSCRIPT,
    PODCAST_SET_MODAL_DETAILS,
    PODCAST_SET_MODAL_CHANNEL_DATA,
    PODCAST_SET_CLIP_TYPE,
    PODCAST_SET_CLIP_LENGTH,
    PODCAST_SET_PLAYER_TIME,
    PODCAST_SET_THE_MANIFEST,
    PODCAST_CLEAR_MODAL_DETAILS,
    PODCAST_CLEAR_MODAL_CHANNEL_DATA,
    PODCAST_SET_SLIDER_RANGE,
    PODCAST_SET_SEGMENT_INDEX,
    PODCAST_SET_MANIFEST_STRING,
    PODCAST_SET_MANIFEST_ROOT,
    PODCAST_RESET_PODCAST,
    PODCAST_SET_TRANSCRIPT_TEXT,
    PODCAST_SET_CLIP_TITLE,
    PODCAST_SET_CLIP_CHANNEL_ID,
    PODCAST_SET_SRT,
    PODCAST_SET_CLIP_PREVIEW_IMAGE,
    PODCAST_SET_CLIP_BACKGROUND_VIDEO,
    PODCAST_SET_ITEM_AUDIO_URL,
    PODCAST_SET_BACK_AGGREGATE,
    PODCAST_SET_FORWARD_AGGREGATE,
    PODCAST_SET_CHANNEL_ID,
    PODCAST_SET_CHANNEL_NAME,
    PODCAST_SET_CHANNEL_IMAGE,
    PODCAST_SET_PUBLISHED_DATE_TIME,
    PODCAST_SET_ITEM_TITLE
} from '../actionTypes';

const INIT_STATE = {
	PODCAST_beginTimeClip: 0,
    PODCAST_endTimeClip: 0,
    PODCAST_absBeginTimeClip: -1,
    PODCAST_absEndTimeClip: 0,
    PODCAST_displayClipSlider: false,
    PODCAST_timelineStart: 0,
    PODCAST_modalDetails: null,
    PODCAST_modalChannelData: null,
    PODCAST_transcript: [],
    PODCAST_clipType: 'start',
    PODCAST_clipLength: 5,
    PODCAST_playerTime: 0,
    PODCAST_segmentIndex: 0,
    PODCAST_sliderRange: {min: 0, max: 100},
    PODCAST_theManifest: null,
    PODCAST_manifestString: null,
    PODCAST_manifestRoot: null,
    PODCAST_transcriptText: null,
    PODCAST_clipTitle: "",
    PODCAST_clipChannelId: null,
    PODCAST_clipPreviewImage: null,
    PODCAST_clipBackgroundVideo: null,
    PODCAST_srt: null,
    PODCAST_itemAudioUrl: null,
    PODCAST_backAggregate: 0,
    PODCAST_forwardAggregate: 0,
    PODCAST_channelId: null,
    PODCAST_channelName: null,
    PODCAST_channelImage: null,
    PODCAST_publishedDateTime: null,
    PODCAST_itemTitle: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case PODCAST_UPDATE_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                PODCAST_beginTimeClip:action.payload
            };
        case PODCAST_UPDATE_END_TIME_CLIP:
            return { 
            ...state, 
                PODCAST_endTimeClip:action.payload
            };
        case PODCAST_UPDATE_ABS_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                PODCAST_absBeginTimeClip:action.payload
            };
        case PODCAST_UPDATE_ABS_END_TIME_CLIP:
            return { 
            ...state, 
                PODCAST_absEndTimeClip:action.payload
            };
        case PODCAST_DISPLAY_CLIP_SLIDER:
            return { 
            ...state, 
                PODCAST_displayClipSlider:action.payload
            };
        case PODCAST_UPDATE_TIMELINE_START:
            return { 
            ...state, 
                PODCAST_timelineStart:action.payload
            };
        case PODCAST_SET_TRANSCRIPT:
            return { 
            ...state, 
                PODCAST_transcript:action.payload
            };
        case PODCAST_SET_MODAL_DETAILS:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.PODCAST_modalDetails);
                return {
                    ...state,
                    PODCAST_modalDetails: newState,
                };
            } else {
                return {
                    ...state,
                    PODCAST_modalDetails: {
                        ...state.PODCAST_modalDetails,
                        ...action.payload,
                    },
                };
            }
        case PODCAST_CLEAR_MODAL_DETAILS:
            return { 
                ...state, 
                    PODCAST_modalDetails:null
                };
        case PODCAST_SET_MODAL_CHANNEL_DATA:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.PODCAST_modalChannelData);
                return {
                    ...state,
                    PODCAST_modalChannelData: newState,
                };
            } else {
                return {
                    ...state,
                    PODCAST_modalChannelData: {
                        ...state.PODCAST_modalChannelData,
                        ...action.payload,
                    },
                };
            }
            case PODCAST_SET_SLIDER_RANGE:
                if (typeof action.payload === 'function') {
                    const newState = action.payload(state.PODCAST_sliderRange);
                    return {
                        ...state,
                        PODCAST_sliderRange: newState,
                    };
                } else {
                    return {
                        ...state,
                        PODCAST_sliderRange: {
                            ...state.PODCAST_sliderRange,
                            ...action.payload,
                        },
                    };
                }
        case PODCAST_CLEAR_MODAL_CHANNEL_DATA:
            return { 
                ...state, 
                    PODCAST_modalChannelData:null
                    };
        case PODCAST_SET_CLIP_TYPE:
            return { 
            ...state, 
                PODCAST_clipType:action.payload
            };
        case PODCAST_SET_CLIP_LENGTH:
            return { 
            ...state, 
                PODCAST_clipLength:action.payload
            };
        case PODCAST_SET_PLAYER_TIME:
            return { 
            ...state, 
                PODCAST_playerTime:action.payload
            };
        case PODCAST_SET_THE_MANIFEST:
            return { 
            ...state, 
                PODCAST_theManifest:action.payload
            };
        case PODCAST_SET_MANIFEST_STRING:
            return { 
            ...state, 
                PODCAST_manifestString:action.payload
            };
        case PODCAST_SET_MANIFEST_ROOT:
            return { 
            ...state, 
                PODCAST_manifestRoot:action.payload
            };    
        case PODCAST_SET_SEGMENT_INDEX:
            return { 
            ...state, 
                PODCAST_segmentIndex:action.payload
            };
        case PODCAST_SET_TRANSCRIPT_TEXT:
            return { 
            ...state, 
                PODCAST_transcriptText:action.payload
            };
        case PODCAST_SET_CLIP_TITLE:
            return { 
            ...state, 
                PODCAST_clipTitle:action.payload
            };
        case PODCAST_SET_CLIP_CHANNEL_ID:
            return { 
            ...state, 
                PODCAST_clipChannelId:action.payload
            };
        case PODCAST_SET_CLIP_PREVIEW_IMAGE:
            return { 
            ...state, 
                PODCAST_clipPreviewImage:action.payload
            };
        case PODCAST_SET_CLIP_BACKGROUND_VIDEO:
            return { 
            ...state, 
                PODCAST_clipBackgroundVideo:action.payload
            };
        case PODCAST_SET_SRT:
            return { 
            ...state, 
                PODCAST_srt:action.payload
            };    
        case PODCAST_SET_ITEM_AUDIO_URL:
            return { 
            ...state, 
                PODCAST_itemAudioUrl:action.payload
            };        
        case PODCAST_SET_BACK_AGGREGATE:
            return { 
            ...state, 
                PODCAST_backAggregate:action.payload
            };        
       case PODCAST_SET_FORWARD_AGGREGATE:
            return { 
            ...state, 
                PODCAST_forwardAggregate:action.payload
            };        
        case PODCAST_SET_CHANNEL_ID:
            return { 
            ...state, 
                PODCAST_channelId:action.payload
            };
        case PODCAST_SET_CHANNEL_NAME:
            return { 
            ...state, 
                PODCAST_channelName:action.payload
            };
        case PODCAST_SET_CHANNEL_IMAGE:
            return { 
            ...state, 
                PODCAST_channelImage:action.payload
            };
        case PODCAST_SET_PUBLISHED_DATE_TIME:
            return { 
            ...state, 
                PODCAST_publishedDateTime:action.payload
            };
        case PODCAST_SET_ITEM_TITLE:
            return { 
            ...state, 
                PODCAST_itemTitle:action.payload
            };    
            case PODCAST_RESET_PODCAST:
            return INIT_STATE;
		default: 
    		return { 
                ...state 
            };
	}
}