export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const PERSIST_SCHEMA_VERSION = '19';
export const PERSIST_SCHEMA_VERSION_KEY = 'apollo-schema-version';

export const firebaseConfig = {
  // get from firebase project settings
  apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: ""
};

export const graphqlURL = `https://db.${process.env.REACT_APP_FB_DOMAIN_ROOT}/graphql`;
export const people_graphqlURL = `https://${process.env.REACT_APP_FB_PEOPLE_DOMAIN_ROOT}/graphql`;
export const people_upload_endpoint = `https://${process.env.REACT_APP_FB_PEOPLE_DOMAIN_ROOT}/people-data-upload`;

export const defaultDirection = "ltr";

export const publishableKey = "pk_test_q9ixpsHLd1kdWvfIH2Ns8RtC";

export const DEFAULT_SUBSCRIPTION = {
  "product_id":"o5c493z78gejvmc",
  "product_price":1099
}