import {
    COMMITTEE_UPDATE_BEGIN_TIME_CLIP,
    COMMITTEE_UPDATE_END_TIME_CLIP,
    COMMITTEE_UPDATE_ABS_BEGIN_TIME_CLIP,
    COMMITTEE_UPDATE_ABS_END_TIME_CLIP,
    COMMITTEE_UPDATE_TIMELINE_START,
    COMMITTEE_DISPLAY_CLIP_SLIDER,
    COMMITTEE_SET_TRANSCRIPT,
    COMMITTEE_SET_MODAL_DETAILS,
    COMMITTEE_SET_MODAL_CHANNEL_DATA,
    COMMITTEE_SET_CLIP_TYPE,
    COMMITTEE_SET_CLIP_LENGTH,
    COMMITTEE_SET_PLAYER_TIME,
    COMMITTEE_SET_THE_MANIFEST,
    COMMITTEE_CLEAR_MODAL_DETAILS,
    COMMITTEE_CLEAR_MODAL_CHANNEL_DATA,
    COMMITTEE_SET_SLIDER_RANGE,
    COMMITTEE_SET_SEGMENT_INDEX,
    COMMITTEE_SET_MANIFEST_STRING,
    COMMITTEE_SET_MANIFEST_ROOT,
    COMMITTEE_RESET_COMMITTEE,
    COMMITTEE_SET_TRANSCRIPT_TEXT,
    COMMITTEE_SET_CLIP_TITLE,
    COMMITTEE_SET_CLIP_CHANNEL_ID,
    COMMITTEE_SET_SRT,
    COMMITTEE_SET_CLIP_PREVIEW_IMAGE,
    COMMITTEE_SET_CLIP_BACKGROUND_VIDEO,
    COMMITTEE_SET_IS_169_CROP_ON,
    COMMITTEE_SET_IS_916_CROP_ON,
    COMMITTEE_SET_IS_11_CROP_ON,
    COMMITTEE_SET_CROP_ASPECT,
    COMMITTEE_SET_CROP_OBJ,
    COMMITTEE_SET_START_CLIP_ID,
    COMMITTEE_SET_END_CLIP_ID,
    COMMITTEE_SET_PREVIOUS_CLIP_ID,
    COMMITTEE_SET_NEXT_CLIP_ID,
    COMMITTEE_SET_PLAYER_RANDOM_STRING,
    COMMITTEE_SET_CLIP_EARLIEST_DATE,
    COMMITTEE_SET_CLIP_LATEST_DATE,
    COMMITTEE_SET_CLIP_CAPTIONS_EARLIEST_DATE,
    COMMITTEE_SET_CLIP_CAPTIONS_LATEST_DATE,
    COMMITTEE_SET_CAPTIONS,
    COMMITTEE_SET_PLAYER_CURRENT_TIME,
    COMMITTEE_SET_SEGMENTS,
    COMMITTEE_SET_ADDED_TIME,
    COMMITTEE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_START,
    COMMITTEE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_END,
    COMMITTEE_SET_ALIGNED_GLOBALLY,
    COMMITTEE_SET_ALIGNED_LOCALLY,
    COMMITTEE_SET_ADDED_PLAYER_TIME,
    COMMITTEE_SET_CLIP_LOAD_BUTTON,
    COMMITTEE_SET_TOTAL_CAPTION_ADJUSTMENT
} from '../actionTypes';

const INIT_STATE = {
	COMMITTEE_beginTimeClip: 0,
    COMMITTEE_endTimeClip: 0,
    COMMITTEE_absBeginTimeClip: -1,
    COMMITTEE_absEndTimeClip: 0,
    COMMITTEE_displayClipSlider: false,
    COMMITTEE_timelineStart: 0,
    COMMITTEE_modalDetails: null,
    COMMITTEE_modalChannelData: null,
    COMMITTEE_transcript: [],
    COMMITTEE_clipType: 'start',
    COMMITTEE_clipLength: 5,
    COMMITTEE_playerTime: 0,
    COMMITTEE_segmentIndex: 0,
    COMMITTEE_sliderRange: {min: 0, max: 100},
    COMMITTEE_theManifest: null,
    COMMITTEE_manifestString: null,
    COMMITTEE_manifestRoot: null,
    COMMITTEE_transcriptText: null,
    COMMITTEE_clipTitle: "",
    COMMITTEE_clipChannelId: null,
    COMMITTEE_clipPreviewImage: null,
    COMMITTEE_clipBackgroundVideo: null,
    COMMITTEE_srt: null,
    COMMITTEE_is169CropOn: false,
    COMMITTEE_is916CropOn: false,
    COMMITTEE_is11CropOn: false,
    COMMITTEE_cropAspect: 16 / 9,
    COMMITTEE_cropObj: { "unit":"%", "x":0, y:0, width:100, height:100 },
    COMMITTEE_startClipId: null,
    COMMITTEE_endClipId: null,
    COMMITTEE_previousClipId: null,
    COMMITTEE_nextClipId: null,
    COMMITTEE_playerRandomString: null,
    COMMITTEE_clipEarliestDate: null,
    COMMITTEE_clipLatestDate: null,
    COMMITTEE_clipCaptionsEarliestDate: null,
    COMMITTEE_clipCaptionsLatestDate: null,
    COMMITTEE_captions: [],
    COMMITTEE_playerCurrentTime: 0,
    COMMITTEE_segments: [],
    COMMITTEE_addedTime: 0,
    COMMITTEE_individualCaptionAdjustmentStart: [],
    COMMITTEE_individualCaptionAdjustmentEnd: [],
    COMMITTEE_alignedGlobally: false,
    COMMITTEE_alignedLocally: false,
    COMMITTEE_addedPlayerTime: 0,
    COMMITTEE_clipLoadButton: false,
    COMMITTEE_totalCaptionAdjustment: 0
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case COMMITTEE_UPDATE_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                COMMITTEE_beginTimeClip:action.payload
            };
        case COMMITTEE_UPDATE_END_TIME_CLIP:
            return { 
            ...state, 
                COMMITTEE_endTimeClip:action.payload
            };
        case COMMITTEE_UPDATE_ABS_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                COMMITTEE_absBeginTimeClip:action.payload
            };
        case COMMITTEE_UPDATE_ABS_END_TIME_CLIP:
            return { 
            ...state, 
                COMMITTEE_absEndTimeClip:action.payload
            };
        case COMMITTEE_DISPLAY_CLIP_SLIDER:
            return { 
            ...state, 
                COMMITTEE_displayClipSlider:action.payload
            };
        case COMMITTEE_UPDATE_TIMELINE_START:
            return { 
            ...state, 
                COMMITTEE_timelineStart:action.payload
            };
        case COMMITTEE_SET_TRANSCRIPT:
            return { 
            ...state, 
                COMMITTEE_transcript:action.payload
            };
        case COMMITTEE_SET_MODAL_DETAILS:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.COMMITTEE_modalDetails);
                return {
                    ...state,
                    COMMITTEE_modalDetails: newState,
                };
            } else {
                return {
                    ...state,
                    COMMITTEE_modalDetails: {
                        ...state.COMMITTEE_modalDetails,
                        ...action.payload,
                    },
                };
            }
        case COMMITTEE_CLEAR_MODAL_DETAILS:
            return { 
                ...state, 
                    COMMITTEE_modalDetails:null
                };
        case COMMITTEE_SET_MODAL_CHANNEL_DATA:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.COMMITTEE_modalChannelData);
                return {
                    ...state,
                    COMMITTEE_modalChannelData: newState,
                };
            } else {
                return {
                    ...state,
                    COMMITTEE_modalChannelData: {
                        ...state.COMMITTEE_modalChannelData,
                        ...action.payload,
                    },
                };
            }
        case COMMITTEE_SET_SLIDER_RANGE:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.COMMITTEE_sliderRange);
                return {
                    ...state,
                    COMMITTEE_sliderRange: newState,
                };
            } else {
                return {
                    ...state,
                    COMMITTEE_sliderRange: {
                        ...state.COMMITTEE_sliderRange,
                        ...action.payload,
                    },
                };
            }
        case COMMITTEE_CLEAR_MODAL_CHANNEL_DATA:
            return { 
                ...state, 
                    COMMITTEE_modalChannelData:null
                    };
        case COMMITTEE_SET_CLIP_TYPE:
            return { 
            ...state, 
                COMMITTEE_clipType:action.payload
            };
        case COMMITTEE_SET_CLIP_LENGTH:
            return { 
            ...state, 
                COMMITTEE_clipLength:action.payload
            };
        case COMMITTEE_SET_PLAYER_TIME:
            return { 
            ...state, 
                COMMITTEE_playerTime:action.payload
            };
        case COMMITTEE_SET_THE_MANIFEST:
            return { 
            ...state, 
                COMMITTEE_theManifest:action.payload
            };
        case COMMITTEE_SET_MANIFEST_STRING:
            return { 
            ...state, 
                COMMITTEE_manifestString:action.payload
            };
        case COMMITTEE_SET_MANIFEST_ROOT:
            return { 
            ...state, 
                COMMITTEE_manifestRoot:action.payload
            };    
        case COMMITTEE_SET_SEGMENT_INDEX:
            return { 
            ...state, 
                COMMITTEE_segmentIndex:action.payload
            };
        case COMMITTEE_SET_TRANSCRIPT_TEXT:
            return { 
            ...state, 
                COMMITTEE_transcriptText:action.payload
            };
        case COMMITTEE_SET_CLIP_TITLE:
            return { 
            ...state, 
                COMMITTEE_clipTitle:action.payload
            };
        case COMMITTEE_SET_CLIP_CHANNEL_ID:
            return { 
            ...state, 
                COMMITTEE_clipChannelId:action.payload
            };
        case COMMITTEE_SET_CLIP_PREVIEW_IMAGE:
            return { 
            ...state, 
                COMMITTEE_clipPreviewImage:action.payload
            };
        case COMMITTEE_SET_CLIP_BACKGROUND_VIDEO:
            return { 
            ...state, 
                COMMITTEE_clipBackgroundVideo:action.payload
            };
        case COMMITTEE_SET_SRT:
            return { 
            ...state, 
                COMMITTEE_srt:action.payload
            };
        case COMMITTEE_SET_IS_169_CROP_ON:
            return { 
            ...state, 
                COMMITTEE_is169CropOn:action.payload
            };    
        case COMMITTEE_SET_IS_916_CROP_ON:
            return { 
            ...state, 
                COMMITTEE_is916CropOn:action.payload
            };    
        case COMMITTEE_SET_IS_11_CROP_ON:
            return { 
            ...state, 
                COMMITTEE_is11CropOn:action.payload
            };    
        case COMMITTEE_SET_CROP_ASPECT:
            return { 
            ...state, 
                COMMITTEE_cropAspect:action.payload
            };
        case COMMITTEE_SET_START_CLIP_ID:
            return { 
            ...state, 
                COMMITTEE_startClipId:action.payload
            };
        case COMMITTEE_SET_END_CLIP_ID:
            return { 
            ...state, 
                COMMITTEE_endClipId:action.payload
            };
        case COMMITTEE_SET_PREVIOUS_CLIP_ID:
            return { 
            ...state, 
                COMMITTEE_previousClipId:action.payload
            };
        case COMMITTEE_SET_NEXT_CLIP_ID:
            return { 
            ...state, 
                COMMITTEE_nextClipId:action.payload
            };
        case COMMITTEE_SET_PLAYER_RANDOM_STRING:
            return { 
            ...state, 
                COMMITTEE_playerRandomString:action.payload
            };
        case COMMITTEE_SET_CLIP_EARLIEST_DATE:
            return { 
            ...state, 
                COMMITTEE_clipEarliestDate:action.payload
            };
        case COMMITTEE_SET_CLIP_LATEST_DATE:
            return { 
            ...state, 
                COMMITTEE_clipLatestDate:action.payload
            };
        case COMMITTEE_SET_CLIP_CAPTIONS_EARLIEST_DATE:
            return { 
            ...state, 
                COMMITTEE_clipCaptionsEarliestDate:action.payload
            };
        case COMMITTEE_SET_CLIP_CAPTIONS_LATEST_DATE:
            return { 
            ...state, 
                COMMITTEE_clipCaptionsLatestDate:action.payload
            };
        case COMMITTEE_SET_CAPTIONS:
            return { 
            ...state, 
                COMMITTEE_captions:action.payload
            };
        case COMMITTEE_SET_PLAYER_CURRENT_TIME:
            return { 
            ...state, 
                COMMITTEE_playerCurrentTime:action.payload
            };
        case COMMITTEE_SET_SEGMENTS:
            return { 
            ...state, 
                COMMITTEE_segments:action.payload
            };
        case COMMITTEE_SET_ADDED_TIME:
            return { 
            ...state, 
                COMMITTEE_addedTime:action.payload
            };
        case COMMITTEE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_START:
            return { 
            ...state, 
                COMMITTEE_individualCaptionAdjustmentStart:action.payload
            };
        case COMMITTEE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_END:
            return { 
            ...state, 
                COMMITTEE_individualCaptionAdjustmentEnd:action.payload
            };
        case COMMITTEE_SET_ALIGNED_GLOBALLY:
            return { 
            ...state, 
                COMMITTEE_alignedGlobally:action.payload
            };
        case COMMITTEE_SET_ALIGNED_LOCALLY:
            return { 
            ...state, 
                COMMITTEE_alignedLocally:action.payload
            };
        case COMMITTEE_SET_ADDED_PLAYER_TIME:
            return { 
            ...state, 
                COMMITTEE_addedPlayerTime:action.payload
            };
        case COMMITTEE_SET_CLIP_LOAD_BUTTON:
            return { 
            ...state, 
                COMMITTEE_clipLoadButton:action.payload
            };
        case COMMITTEE_SET_TOTAL_CAPTION_ADJUSTMENT:
            return { 
            ...state, 
                COMMITTEE_totalCaptionAdjustment:action.payload
            };
        case COMMITTEE_SET_CROP_OBJ:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.COMMITTEE_cropObj);
                return {
                    ...state,
                    COMMITTEE_cropObj: newState,
                };
            } else {
                return {
                    ...state,
                    COMMITTEE_cropObj: {
                        ...state.COMMITTEE_cropObj,
                        ...action.payload,
                    },
                };
            };      
        case COMMITTEE_RESET_COMMITTEE:
            return INIT_STATE;
		default: 
    		return { 
                ...state 
            };
	}
}