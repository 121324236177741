import React from 'react'
import { connect } from 'react-redux';
import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import './scss/style.scss';
import history from './history';
import ReactGA from 'react-ga';
import { userLoggedIn, superAdminLoggedIn, adminLoggedIn, projectSelected } from './apollo/cache';
import { publishableKey } from './constants/defaultValues'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js'
//ReactGA.initialize('UA-158587-7');

// history.listen(location => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const SuperAdminRoute = ({ component: Component, loginUser, ...rest }) => {
  return (
    <Route
      path="/superadmin/" 
      name="SuperAdmin App" 
      render={props => 
        superAdminLoggedIn() ? (
          <TheSuperAdminApp {...props} />
        ) : ( 
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }}
          /> 
        ) 
      } 
    />
  );
}

const AdminRoute = ({ component: Component, loginUser, ...rest }) => {
  return (
    <Route
      path="/admin/" 
      name="Admin App" 
      render={props => 
        adminLoggedIn() ? (
          <TheAdminApp {...props} />
        ) : ( 
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location }
            }}
          /> 
        ) 
      } 
    />
  );
}

const AuthRoute = ({ component: Component, loginUser, ...rest }) => {
  console.log(projectSelected())
  return (
    <Route
      {...rest}
      render={props => 
        userLoggedIn() && projectSelected() ? (
          <TheLayout {...props} />
        ) : userLoggedIn() ? (
          <Redirect
            to={{
              pathname: '/select',
              state: { from: props.location }
            }} 
          />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Home = React.lazy(() => import('./views/pages/home/Home'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));
const Privacy = React.lazy(() => import('./views/pages/home/Privacy'))
const SelectProject = React.lazy(() => import('./views/pages/selectProject/SelectProject'));
const ResetPassword = React.lazy(() => import('./views/pages/forgotPassword/ResetPassword'));
const SetPassword = React.lazy(() => import('./views/pages/forgotPassword/SetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const stripePromise = loadStripe(publishableKey);

// Admin App
const TheAdminApp = React.lazy(() => import('./views/admin/AdminApp'));

// SuperAdmin App
const TheSuperAdminApp = React.lazy(() => import('./views/superadmin/SuperAdminApp'));


const App = (props) => {

    const { auth } = props;
    console.log(props)

    return (
      <Router history={history} basename={'/'}>
        <React.Suspense fallback={loading}>
        <Elements stripe={stripePromise}>
          <Switch>
            <Route exact path="/" name="Home" render={props => <Home />} />
            <Route exact path="/privacy-policy" name="Privacy Policy" render={props => <Privacy />} />
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
            <Route exact path="/select" name="Select Project" render={props => <SelectProject {...props}/>} />
            <Route exact path="/forgotpassword" name="Forgot Password Page" render={props => <ForgotPassword {...props}/>} />
            <Route exact path="/resetpassword" name="Reset Password Page" render={props => <ResetPassword {...props}/>} />
            <Route exact path="/setpassword" name="Set Password Page" render={props => <SetPassword {...props}/>} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
            <SuperAdminRoute
              name="SuperAdmin"
              path="/superadmin"
              auth={auth}
              render={props => <TheLayout {...props} />}
            />
            <AdminRoute
              name="Admin"
              path="/admin"
              auth={auth}
              render={props => <TheLayout {...props} />}
            />            
            <AuthRoute
              name="Home"
              path="/"
              auth={auth}
              render={props => <TheLayout {...props} />}
            />
          </Switch>
          </Elements>
        </React.Suspense>
      </Router>
    );
  }

const mapStateToProps = ({ auth, settings }) => {
  const { locale } = settings;
  return { auth, locale };
};
const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);