import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import { hydrate, render, ReactDOM } from 'react-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from "./redux/store";
import { ApolloProvider } from "@apollo/client";
import App from './App';
import { people_client, client } from './apollo/';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { publishableKey } from './constants/defaultValues'
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons'
import { Provider } from 'react-redux'

//console.log = function() {};

(async () => {

    React.icons = icons

    const stripePromise = loadStripe(publishableKey);

    const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
      hydrate(<React.StrictMode>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={people_client}>
                <ApolloProvider client={client}>
                <App/>
                </ApolloProvider>
              </ApolloProvider>
            </PersistGate>
          </Provider>
        </Elements>
      </React.StrictMode>, rootElement);
    } else {
      render(<React.StrictMode>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={people_client}>
                <ApolloProvider client={client}>
                  <App/>
                </ApolloProvider>
              </ApolloProvider>
            </PersistGate>
          </Provider>
        </Elements>
      </React.StrictMode>, rootElement);
    }

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <ApolloProvider client={apollo}>
//           <App/>
//         </ApolloProvider>
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>, 
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();

})()