import { setContext } from 'apollo-link-context';
import { ApolloClient, ApolloLink, HttpLink, concat } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { graphqlURL, people_graphqlURL } from '../constants/defaultValues'
import { persistCache as PersistCache } from 'apollo3-cache-persist';
import { PERSIST_SCHEMA_VERSION, PERSIST_SCHEMA_VERSION_KEY } from '../constants/defaultValues';
import { cache } from './cache';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => { 
      console.log(message); 
      console.log(JSON.stringify(graphQLErrors));
      return true;
    } )
})

const httpLink = new HttpLink({ uri: graphqlURL });
const people_httpLink = new HttpLink({ uri: people_graphqlURL });

const authMiddleware = setContext(async () => {
    if (localStorage.getItem('token') === null) {
      localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJTVERCIiwidXNlcm5hbWUiOiJndWVzdCIsImFkbWluIjpmYWxzZSwic3VwZXJhZG1pbiI6ZmFsc2V9.MG-_mGDhwiyKw4r-rcQO1jhJuM_Rcznn-qp4peP6DoY');
    }
    const token = localStorage.getItem('token');
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });  

const httpLinkWithAuthToken = concat(authMiddleware, httpLink);
const people_httpLinkWithAuthToken = concat(authMiddleware, people_httpLink);

const currentVersion =localStorage.getItem(PERSIST_SCHEMA_VERSION_KEY)

const persistCache = new PersistCache({
  cache,
  storage: window.localStorage,
});

const client = new ApolloClient({
  cache: cache,
  persistCache: persistCache,  
  link: ApolloLink.from([errorLink, httpLinkWithAuthToken]),
  connectToDevTools: true
});

const people_client = new ApolloClient({
  cache: cache,
  persistCache: persistCache,  
  link: ApolloLink.from([errorLink, people_httpLinkWithAuthToken]),
  connectToDevTools: true
});

if (currentVersion !== PERSIST_SCHEMA_VERSION) {
  client.resetStore();
  localStorage.setItem(PERSIST_SCHEMA_VERSION_KEY, PERSIST_SCHEMA_VERSION)
}

window.__APOLLO_CLIENT__ = client;

export { client, people_client };