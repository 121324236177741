import {defaultLocale,localeOptions} from '../../constants/defaultValues'

import {
    SET_DARK_MODE,
    SET_ASIDE_SHOW,
    SET_SIDEBAR_SHOW,
    SET_SEARCH_RESULTS,
} from '../actions';

const initialState = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false,
    searchResults: false,
    mapCount: 0,
    showInfoBox: false,
    pollMap: {}
};

// export default (state = initialState, { type, ...rest }) => {
//     switch (type) {
//       case 'set':
//         console.log(type);
//         return {...state, ...rest };
//       default:
//         console.log(rest);
//         console.log(type);
//         return state
//     }
// };

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_DARK_MODE:
		    return { ...state, darkMode:action.darkMode};
        case SET_ASIDE_SHOW:
            return { ...state, asideShow:action.asideShow};
        case SET_SIDEBAR_SHOW:
            return { ...state, sidebarShow:action.sidebarShow};
        case SET_SEARCH_RESULTS:
            return { ...state, searchResults:action.searchResults};
		default: 
		    return { ...state };
	}
}